<template>
  <div class="d-flex flex-column gap-2">
    <v-text-field
      v-if="!mini"
      v-model="quantity"
      outlined
      dense
      flat
      append-icon="mdi-plus-box-outline"
      prepend-inner-icon="mdi-minus-box-outline"
      type="number"
      class="text-center quantity"
      :hide-details="true"
      :min="1"
      :max="singleProductData.quantity"
      @input="changed"
      @click:append="addQty"
      @click:prepend-inner="removeQty"
    />

    <v-btn
      block
      :small="mini"
      :disabled="singleProductData.quantity <= singleProductData.qty || singleProductData.quantity === 0"
      :loading="loading"
      color="primary"
      class="text-capitalize flex-grow-0 flex-shrink-1"
      @click="addToCart"
    >
      {{ $t("Acheter") }}
    </v-btn>
    <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <v-icon color="success">
            mdi-check
          </v-icon>
          {{ $t("Produit ajouté au panier avec succès") }}
          <v-spacer />
          <v-btn
            icon
            color="red"
            @click.stop="dialog=false"
          >
            <v-icon color="red">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-img
                :lazy-src="singleProductData.image"
                :src="singleProductData.image"
                style="max-width: 100%;height: auto;width: 100%;"
              />
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <router-link
                :to="`/products/${singleProductData.slug}`"
                class="text-decoration-none"
              >
                <h1 class="mt-4 mb-4 leading-tight teg-gray text-h6">
                  {{ product.name }}
                </h1>
              </router-link>
              <h5 class=" mb-2">
                <span class=" font-weight-light">{{ $t("Quantité") }}:</span>
                <span class="float-right">{{ singleProductData.qty }}</span>
              </h5>
              <h5 class=" mb-2">
                <span class=" font-weight-light">{{ $t("Prix") }}:</span>
                <span class="float-right font-weight-bold">
                  <span class="">€ {{ singleProductData.price_without_tax }}</span> <span
                    v-if="singleProductData.reduction_percentage > 0"
                    class="text-decoration-line-through"
                  >€ {{ singleProductData.price_with_tax }}</span>
                </span>
              </h5>

              <h5 class=" mb-2">
                <span class=" font-weight-light">{{ $t("Total") }}:</span>
                <span class="float-right">€ {{ singleProductData.total }}</span>
              </h5>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="primary"
            :small="$vuetify.breakpoint.smAndDown"
            outlined
            @click.stop="dialog=false"
          >
            {{ $t("Continuer les achats") }}
          </v-btn>
          <v-spacer />
          <v-btn
            to="/checkout"
            :small="$vuetify.breakpoint.smAndDown"
            color="primary"
          >
            {{ $t("Commander") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  import { mapActions, mapMutations } from 'vuex'
  import ProductMedia from './ProductMedia.vue'

  export default {
    name: 'AddToCartButton',
    directives: {
    },
    components: {
      ProductMedia,
    },
    props: {
      product: {
        type: Object,
        default: () => {},
      },
      mini: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      quantity: 1,
      loading: false,
      dialog: false,
      singleProductData: {},
    }),
    computed: {

    },
    created () {
      this.singleProductData = this.product
    },
    methods: {
      ...mapActions(['addCart', 'removeCart']),
      ...mapMutations([
        'pushItem', // map `this.increment()` to `this.$store.commit('increment')`
        'removeItem', // map `this.increment()` to `this.$store.commit('increment')`
        // `mapMutations` also supports payloads:
        'setValue', // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
      ]),
      changed () {
        this.quantity = parseInt(this.quantity)
        if (this.quantity < 1) {
          this.quantity = 1
        }
      },
      removeQty () {
        if (this.quantity > 1) {
          this.quantity -= 1
          this.singleProductData.qty = this.quantity
          // this.addCart(this.singleProductData)
        }
      },
      addQty () {
        if (this.product.quantity > this.quantity) {
          this.quantity = parseInt(this.quantity) + 1
          this.singleProductData.qty = this.quantity
          // this.addCart(this.singleProductData)
        }
      },
      addCartx (data) {
        this.addCart(data)
      },
      removeCartx (data) {
        this.removeCart(data)
      },
      addToCart: function () {
        this.loading = true
        axios.post(`/cart/add/${this.product.id}`, {
          quantity: this.quantity,
          product: this.product.id,
          cart_id: this.$store.state.cart.id,

        })
          .then((response) => {
            this.loading = false
            if (response.data && response.data.error) {
              this.toast(response.data.message, 'red')
            } else {
              if (response.data && response.data.id) {
                this.singleProductData.qty = response.data.qty
                this.singleProductData.total = response.data.prix_ht
                this.singleProductData.total = response.data.prix_ht
                this.dialog = true
                var cart = this.$store.state.cart
                cart.id = response.data.cart_id
                this.setValue({ type: 'cart', value: cart })
                this.$store.commit('ADD_CART', this.singleProductData)
              }
            }
          }).then(() => {
            this.loading = false
          })
      },
    },
  }
</script>
