<template>
  <v-dialog
    v-model="loading"
    persistent
    width="70"
    class="overflow-hidden transparent"
  >
    <v-card
      width="70"
      height="70"
      class="overflow-hidden"
    >
      <v-row
        no-gutters
        justify="center"
        align="center"
        class="fill-height align-self-center"
      >
        <v-col
          cols="12"
          class="text-center"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            class="mx-auto"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'Overlay',
    props: {
      persistent: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      loading: true,
    }),
  }
</script>
