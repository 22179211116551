<template>
  <v-chip
    v-if="clockType === 'clock' && !ended"
    class="clock"
    color="primary"
    :small="!single && $vuetify.breakpoint.smOnly"
    :x-small="!single && $vuetify.breakpoint.xsOnly"
    v-html="leftclock"
  />
<!--  <v-progress-circular
    v-else-if="percentage < 100"
    :color="colorCircular"
    width="1.5"
    size="18"
    :title="leftclock"
    :value="percentage"
  >
    {{ Math.floor(percentage) }}
  </v-progress-circular>-->
</template>

<script>
  import moment from 'moment'
  export default {
    props: [
      'future', 'now', 'total', 'start', 'type', 'single',
    ],
    data () {
      return {
        leftclock: '',
        ended: false,
        interval: null,
        nowthis: null,
        dateStart: null,
        dateEnd: null,
        percentage: 100,
        clockType: 'clock',
      }
    },
    computed: {
      color () {
        if (this.percentage <= 20) {
          return 'red--text'
        }
        if (this.percentage <= 50) {
          return 'warning--text'
        }
      },
      colorCircular () {
        if (this.percentage <= 20) {
          return 'red'
        }
        if (this.percentage <= 50) {
          return 'warning'
        }
        if (this.percentage > 50) {
          return 'green'
        }
      },
    },
    destroyed () {
      clearInterval(this.interval)
    },
    mounted () {
      var self = this
      this.clockType = this.type || 'clock'
      this.nowthis = moment(this.now)
      this.dateStart = moment(this.start)
      this.dateEnd = moment(this.future)
      self.timeLeft()
      this.interval = setInterval(function () {
        self.timeLeft()
      }, 1000)
    },
    methods: {
      timeLeft () {
        var n = this.nowthis.add(1, 'seconds')
        var f = moment(this.future)

        if (this.dateStart && this.dateStart.isAfter(n)) {
          this.leftclock = '<span>' + this.dateStart.format('D/M HH:mm') + '</span>'
          return
        }
        if (this.dateEnd && this.dateEnd.isBefore(n)) {
          this.ended = true
          this.leftclock = "<span style='color:red'>" + this.dateEnd.format('D/M HH:mm') + '</span>'
          return
        }

        var ms = f.diff(n)

        if (ms > 0) {
          var d1 = moment.duration(ms)
          var minutesLeft = d1.asMinutes()
          if (this.total && this.total > 0) {
            this.percentage = (minutesLeft / this.total) * 100
            this.$emit('percentage', this.percentage)
          }

          var s = Math.floor(d1.asHours()) + moment.utc(ms).format(':mm:ss')/* +":"+d1.minutes() +":"+ d1.seconds() */

          this.leftclock = s
        } else {
          this.leftclock = "<span style='color:red'>00:00:00</span>"
          clearInterval(this.interval)
        }
      },
    },
  }
</script>

<style>

</style>
