<template>
  <!-- card-hover-shadow  -->
  <v-dialog
    v-model="dialog"
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        color="secondary"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-eye</v-icon>
      </v-btn>
    </template>
    <base-card>
      <v-card-title>
        <v-btn
          class="mt-4"
          absolute
          right
          icon
          color="secondary"
          text
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <product-media
              v-model="product"
              :product="product"
              height="250"
              width="100%"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <router-link
              :to="`/products/${product.slug}`"
              class="text-decoration-none"
            >
              <h1 class="mt-4 mb-4 leading-tight teg-gray text-h6 text-md-5 text-lg-4 text-xl-4">
                {{ product.name }}
              </h1>
            </router-link>
            <h5
              v-if="product.category"
              class="grey--text text--darken-5 mb-3"
            >
              {{ $t("Category") }}: <router-link
                :to="`/categories/${product.category.slug || 1}`"
                class="text-decoration-none"
              >
                <span class="grey--text text--darken-1 font-weight-medium ml-2">{{ product.category_name || '' }}</span>
              </router-link>
            </h5>
            <div
              v-if="product.reviews_avg_rating > 0"
              class="d-flex mb-3"
            >
              <h5 class="grey--text text--darken-5 mr-2">
                {{ $t("Rated") }}:
              </h5>
              <div class="d-flex mb-1">
                <v-rating
                  :value="product.reviews_avg_rating"
                  color="amber"
                  dense
                  half-increments
                  readonly
                  size="16"
                />

                <div class="grey--text text--darken-1 font-weight-bold ml-1">
                  ({{ product.reviews_count }})
                </div>
              </div>
            </div>
            <h2 class="font-weight-bold mb-2">
              <span class="font-weight-bold primary--text text-h6">€ {{ product.price_without_tax }}</span> <span
                v-if="product.reduction_percentage > 0"
                class="gray--text font-weight-bold text-h6 text-decoration-line-through"
              >€ {{ product.price_with_tax }}</span>
            </h2>
            <h5 class=" font-weight-light mb-3">
              <span v-if="product.quantity">{{ product.quantity }} {{ $t("Stock disponible") }}</span>
              <span v-else>{{ $t("En rupture de stock") }}</span>
            </h5>
            <div class="mb-6">
              <v-row
                no-gutters
                class="d-flex"
              >
                <v-col
                  cols="auto"
                  md="6"
                  lg="6"
                  xl="6"
                >
                  <add-to-cart-button
                    :product="product"
                    :mini="false"
                  />
                </v-col>
              </v-row>
            </div>
            <h5
              v-if="product.user"
              class="grey--text text--darken-5 mb-3"
            >
              {{ $t("Vendeur") }}: <vendor-link
                :vendor="{...product.user,
                          path: `/profile/${product.user.id || ''}`,
                }"
              />
            </h5>
          </v-col>
        </v-row>
      </v-card-text>
    </base-card>
  </v-dialog>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import VendorLink from '../VendorLink'
  import AddToCartButton from './AddToCartButton'

  export default {
    name: 'ProductModal',
    components: { ProductMedia: () => import('./ProductMedia'), AddToCartButton, VendorLink },
    props: {
      product: {
        type: Object,
        default: () => {},
      },
      btnText: {
        type: String,
        default: '',
      },
      cardHoverShadow: {
        type: Boolean,
        default: false,
      },
      counter: {
        type: Number,
        default: 0,
      },
      contentImg: String,
      contentText: String,
      amount: {
        type: Number,
        default: 200,
      },
      contentRating: {
        type: Number,
        default: 4.5,
      },
    },
    computed: {
      ...mapGetters(['getProducts']),

    },
    data () {
      return {
        dialog: false,

      }
    },
    methods: {
      ...mapActions(['addCart']),

    },
  }
</script>
<style lang="scss" scoped>

    .br-t-8 {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
  .card-hover {
    &:hover {
      .card-hover-icon {
        display: block;
      }
    }
    .card-hover-icon {
      display: none;
    }
  }

</style>
